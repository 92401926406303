
* {
    margin: 0;
    padding: 0;
}
.my-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999999;
    transition: all 0.3s;
    cursor: default;
    user-select: none;
    .main {
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        background-color: #fff;
        border-radius: 4px;
        overflow: hidden;
        .head {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 20px 0;
            font-size: 16px;
            color: #060111;
            .left {
                display: flex;
                align-items: center;
                font-weight: 700;
            }
            .left::before {
                display: block;
                content: "";
                width: 4px;
                height: 12px;
                margin-right: 10px;
                background-color: #2821fc;
            }
            .right {
                position: absolute;
                right: 20px;
                top: 70%;
                transform: translateY(-50%);
                font-size: 22px;
                cursor: pointer;
            }
        }
        .center {
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            /* margin: 20px 0; */
            min-height: 100px;
            /* padding: 15px 0 0px; */
            text-align: center;
            .center-content {
                width: 100%;
                color: #1b162a;
                font-size: 14px;
            }
        }
        .footer {
            display: flex;
            justify-content: center;
            padding-bottom: 20px;
            /* margin-top: 20px; */
            .item {
                padding: 10px 25px;
                margin-right: 15px;
                font-size: 14px;
                border: none;
                border-radius: 5px;
                background-color: #fff;
                cursor: pointer;
                transition: all 0.3s;
            }
            .item:last-child {
                margin-right: 0;
            }
            .confirm {
                position: relative;
                color: #fff;
            }
            .confirm:hover .mask {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                transition: all 0.3s;
                background-color: rgba(255, 255, 255, 0.2);
            }
            .cancel {
                color: #555;
                border: 1px solid #ccc;
                background-color: transparent;
            }
        }
    }
}

.black {
    #main {
        background-color: #0c0e3f;
        .head .left,
        .head .right,
        .center-content {
            color: #fff;
        }
        .center-content {
            padding: 0 30px;
            text-align: start;
        }
        .footer {
            .confirm {
                background-color: #4b45ff;
            }
            .cancel {
                color: #fff;
            }
        }
    }
}
